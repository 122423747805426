<template>
  <fieldset
    class="form__fieldset form__fieldset--collapsable"
    :class="{ active: finalIsOpened, 'has-error': hasError }">
    <slot name="title">
      <h4 v-if="title" class="form__fieldset-title flex" :class="{ active: finalIsOpened }">
        <span class="flex flex-middle cursor-pointer" @click="toggle()">
          <icon v-if="icon && !hasError" :icon="icon"></icon>
          <icon
            v-if="hasError"
            v-tooltip="{ content: 'core.form.groupHasErrorTooltip', theme: 'error' }"
            icon="warning-triangle"
            theme="danger"></icon>
          {{ translateTitleCase(title) }}
          <icon-halo
            class="expand-icon margin-m-inline-start"
            theme="bg"
            halo-theme="alt"
            :halo-ratio="0.2"
            :size="1"
            icon="chevron-inline-end"></icon-halo>
        </span>
      </h4>
    </slot>

    <div
      v-show="finalIsOpened"
      v-responsive-classes
      class="form__fieldset-content animate--fast animate__fadeIn"
      :class="contentClass">
      <slot class="bogle"></slot>
    </div>
  </fieldset>
</template>

<script>
import formFieldSetMixin from "@/client/extensions/mixins/baseComponents/form/formFieldSet.js";

export default {
  mixins: [formFieldSetMixin],
  props: {
    maxFieldsPerRow: {
      type: [Number, String],
      default: 1,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    // allow toggle through v-model
    modelValue: {
      type: Boolean,
      default: null,
    },
    // allow toggle through opened prop
    opened: {
      type: Boolean,
      default: null,
    },
    // allow to have this initiallly closed or open,
    // but the componetn can stsill manage it's own state
    initiallyOpened: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["fieldset:opened", "fieldset:closed", "update:modelValue"],
  data: function () {
    return {
      innerOpened: !!this.initiallyOpened,
    };
  },
  computed: {
    contentClass() {
      let result = [];

      if (+this.maxFieldsPerRow > 1) {
        result.push("max-" + this.maxFieldsPerRow + "-per-row");
      }
      return result;
    },
    finalIsOpened() {
      if (this.opened !== null) {
        return this.opened;
      }
      return this.innerOpened;
    },
  },
  methods: {
    open() {
      this.innerOpened = true;
      this.$emit("fieldset:opened", true);
      this.$emit("update:modelValue", true);
    },
    close() {
      this.innerOpened = false;
      this.$emit("fieldset:closed");
      this.$emit("update:modelValue", false);
    },
    toggle() {
      if (this.finalIsOpened) {
        this.close();
      } else {
        this.open();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
